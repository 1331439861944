.doc-content{
	margin-top:20px;
	font-size:16px;
}

.doc-content ul, .doc-content li {
	font-size:16px;
    line-height:1.5;	
    list-style:outside;
	margin-left:8px;
}

.doc-content ol li {
    list-style-type:decimal;
}

.doc-content p{
	font-size:16px;
}

.doc-content .btn{
	min-width:200px;
}

.doc-hl {
    color:#ff9700;
    font-weight:bold;
}

.doc-content a, .doc-content a:hover {
    color:#0366d6;
}

.doc-content a.active {
    color:#ffce3d;
}

.doc-hl-lp {
    padding-left:15px;
    color:#ffce3d;
}

.doc-sec {
    color:#666;
}

.doc-content .card-body {
    line-height:1.3;
    margin-right:20px;
}

.doc-content-indended {
    margin-left:20px;
}
