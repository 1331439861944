.pricing-column ul {
    margin:10px 0 10px 0;
	background:#eee;
	border: 1px solid #aaa;
}

.pricing-column li {
   list-style-type:none; 
   text-align:center;
   padding:20px 5px 20px 5px;
   margin:0;
   border-bottom: 1px solid #ccc;
}

.pricing-column .header {
  background-color: #ffc107;
  color: black;
  font-size: 22px;
  font-weight: bold;
  padding:2px 0px 20px 0px;
  border:none;
}

.pricing-column .header-small {
  background-color: #ffc107;
  color: black;
  font-size: 16x;
  padding:20px 0px 2px 0px;
  border:none;
}

.pricing-column .price {
  font-size:36px;
  font-weight:bold;
  padding:20px 20px 0px 20px;
  border:none;
}

.pricing-column .details-big{
  padding:20px 10px 0px 10px;
  border:none;
}

.pricing-column .currency{
  font-size:20px;
  font-weight:bold;
  vertical-align:text-top;
}

.pricing-column .price-small {
  color:#777;
  margin-top:-5px;
  padding:0px 20px 20px 20px;
}

.pricing-column .details-small {
  color:#777;
  padding:0px 20px 20px 20px;
}

.pricing-column a, .pricing-column a:hover {
  color:#212529;
  font-weight:bold;
}

.pricing-column .number{
  font-size:18px;
  font-weight:bold;
}

.pricing-column .additional-info{
  min-height:89px;
}



.pricing-content .jumbotron {
  margin:10px 0 10px 0;
  font-size:18px;
  text-align:center;
}

.pricing-content .jumbotron p{
  color:#ddd;
  line-height:1.4;
}

.pricing-content .jumbotron a{
  font-weight:bold;
}

.pricing-content .action-btn{
  padding:8px;
}
