body {
	color: #222;
	background: #f4f4f4;
	font: 400 14px CoreSans, Arial,sans-serif;
}

a {
	color: #222;
}

a:hover {
	text-decoration: underline;
}

ul, li {
	list-style: none;
	padding: 0;
	margin: 0;
}

input {
	padding: 10px;
	border-radius: 5px;
	outline: none;
	margin-right: 10px;
	border: 1px solid #dddddd;
}

button {
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #dddddd;
	background: transparent;
	color: #808080;
	cursor: pointer;
}

button:hover {
	color: #222;
}

input[type=file] { 
  visibility: hidden;
  position: absolute
}

*:focus {
	outline: none;
}

@media all and (min-width: 480px) {
  .login {
    padding: 20px 0 20px 0;
  }

  .login form, .login-bottom {
    margin: 0 auto;
    max-width: 380px;
  }
}


.login {
    padding: 5px 0 10px 0;
}

.login form{
    color:#ddd;
	padding:15px;
    background: #1a1f24;
    border:1px solid #222;
}

.login input, .login input:focus{
    color:white;
	background: black;
	border-color:#333;
}

.login select, .login select:focus{
    color:white;
	background: black;
	border-color:#333;
    line-height:2;
}

.login input:focus, .login select:focus{
	box-shadow: 0 0 0 3px #9e7700;
}

.login-bottom{
    color:#eee;
	padding:15px;
	margin-bottom:20px;
    background: #1a1f24;
	text-align:center;
    border:1px solid #333;
}

a:hover {
    color:#ffce3d;
}

a {
    color:#c99700;
}

html,body{
	height:100%;
	position:relative;
	background:black;
	-webkit-font-smoothing:antialiased;
	font-family:Roboto,Helvetica,Arial,sans-serif;
}

#root{
	min-height:100vh;
	position:relative;
	overflow:hidden;
	display:block;
	padding-top:50px;
	padding-bottom:260px;
}

.header{
	background:#343a40;
}

.header-center{
    margin-top:10px;
    text-align:center;
}

.header-center-title{
    color:#ddd;
    text-align:center;
    margin-top:20px;
    font-size:28px;
}

.header-center-subtitle{
    color:#ddd;
    text-align:center;
    margin:10px 0 5px 0;
    font-size:28px;
}

.page {
	margin:20px;
}

.interactions {
	margin-top:20px;
	text-align:center;
}

.table {
	margin: 15px 0;
	color:#888;
}

.url-value{
    font-size:18px;
	color:white;
	white-space:normal;
	word-wrap:anywhere;
	word-wrap:break-word;
}
.text-value {
	color:#ddd;
	white-space:normal;
	word-wrap:anywhere;
	word-wrap:break-word;
}

.table-header{
	display: -webkit-flex;
	display: flex;
	line-height: 24px;
	font-size: 16px;
	padding:0 10px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.table-empty {
	color:#eee;
	margin: 200px;
	text-align: center;
	font-size: 16px;
}

.table-row-top {
	margin-top:5px;
	display: -webkit-flex;
	display: flex;
	white-space: nowrap;
}

.table-row-bottom{ 
	display: -webkit-flex; 
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items:flex-start;
	        align-items:flex-start;
    padding:5px;
}

.table-row-image-container{
	margin:5px;	
	min-height:210px;
	min-width:260px;
	border:1px solid #444;
	transition: background .15s ease-in-out, border-color .15s ease-in-out;
	position:relative;
}

.table-overlay{
	position: absolute;
	top: 0;
	width: 100%;
    height: 100%;
	background: #222;
	opacity: 0.6;
	z-index: 1;
}

.table-overlay> .spinner-border{
	position:absolute;
	left:50%;
	margin-left:-16px;
	top:50%;
	margin-top:-16px;
    color:white;
}

.table-row-add-button-container{
	margin:5px;
}

.table-row-action-buttons-container{
    display:-webkit-flex;
    display:flex;
	-webkit-flex-grow:1;
	        flex-grow:1;
	-webkit-justify-content:flex-end;
	        justify-content:flex-end;
	-webkit-align-self:flex-end;
	        align-self:flex-end;
}

.table-action-button {
    margin:5px;
	width:100px;
}

.table-action-button-large {
    margin:5px;
	width:150px;
}

.table-commiting-container{
    margin:10px;
}

.table-row-image-content{
	margin:5px;	
    text-align:center;
	-webkit-flex-grow:1;
	        flex-grow:1;
	position:relative;
    background-image: linear-gradient(45deg, #404040 25%, transparent 25%), linear-gradient(-45deg, #404040 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #404040 75%), linear-gradient(-45deg, transparent 75%, #404040 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.table-row-image-content:before{
    content:' ';
    display:inline-block;
    vertical-align:middle;
    height:100%;
}

.table-row-image-row{
	display:-webkit-flex;
	display:flex;
	text-align:center;
}


.table-row-right-container{
	float:right;
    margin:5px 5px 5px 0px;
}

.table-row-bottom-container{
    margin:0px 5px 10px 5px;
	text-align:center;
}

.table-row-lang-container{
    margin: 5px;
    padding: 5px 1px 0 1px;
    border-top: 1px solid #444;
}

.table-row-lang-label{
	font-size:16px
}

.table-row-lang-value{
	color:#eee;
	font-size:16px;
	cursor:pointer;
}

.table-row-lang-container .btn{
	padding: 0 6px;
}

.table-row-bottom-container > .btn-group > .btn,
.div-vertical-content> .btn-group > .btn{
    padding: 0 6px;
    width:67px;
}

.table-row-image-container:hover{
	background:black;
	border-color:#666;
}

.table-row-image{
	max-height:200px;
	max-width:100%;
	object-fit: contain;
	cursor:pointer;
	line-height:200px;
	text-align:center;
    display:inline-block;
    vertical-align:middle;
}

.table-row-add-button{
	color:#ffc107;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-direction:column;
	        flex-direction:column;
	width:260px;
	height:260px;
	text-align:center;
	cursor:pointer;
	border:1px solid #444;
	box-sizing:border-box;
	transition: color .15s ease-in-out, background .15s ease-in-out, border-color .15s ease-in-out;
	margin:0;
}

.table-row-add-button:hover{
	color:#ecb306;
	background:black;
	border-color:#666;
}

.table-row-add-button-content{
	margin:auto;
}

.table-row-title{
	padding:2px 1px;
	background:#4f575e;
	text-align:center;
	border-radius:5px 5px 0px 0px;
	border:1px solid #4f575e;
    color:#ccc
}

.table-row{
	position:relative; 
	white-space: nowrap;
	margin:10px 10px 20px 10px;
	background: #1a1f24;
	border-radius:5px;
	border:1px solid #1a1f24;
}

.table-row > .spinner-border{
	position:absolute;
	left:50%;
	margin-left:-16px;
	top:50%;
	margin-top:-16px;
}

.table-header > span {
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5px;
}

.table-row > span {
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5px;
}

.button-inline {
	border-width: 0;
	background: transparent;
	color: inherit;
	text-align: inherit;
	-webkit-font-smoothing: inherit;
	padding: 0;
	font-size: inherit;
	cursor: pointer;
}

.button-active {
	border-radius: 0;
	border-bottom: 1px solid #38BB6C;
}

.button-holder {
	display:inline-block;
	width:260px;
}

.button-delete {
    float:right;
	cursor: pointer;
    padding: 15px 7px 5px 17px;
}

.button-delete-imageset {
	float:right;
	cursor: pointer;
}

.button-delete > .material-icons, .button-delete-imageset > .material-icons{
	color:#c62828;
	vertical-align:middle;
	transition: color .15s ease-in-out, background .15s ease-in-out, border-color .15s ease-in-out;
}

.button-delete > .material-icons:hover, .button-delete-imageset > .material-icons:hover{
	color:#f44336;
}

.button-edit-imageset {
	float:right;
	cursor: pointer;
}

.button-copy-url{
	cursor: pointer;
	margin: 0 5px;
    padding: 0 5px;
}

.button-edit > .material-icons, .button-edit-imageset > .material-icons, .button-copy-url > .material-icons{
	color:#999;
	vertical-align:middle;
	transition: color .15s ease-in-out, background .15s ease-in-out, border-color .15s ease-in-out;
}

.button-edit> .material-icons:hover, .button-edit-imageset > .material-icons:hover, .button-copy-url > .material-icons:hover{
	color:#bbb;
}

.btn-secondary{
	color:#ccc;
}

.div-vertical{
    -webkit-transform: rotate(-90deg) translate(0,45px);
            transform: rotate(-90deg) translate(0,45px);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
	width:45px;
	height:200px;
    text-align:center;
    display:-webkit-flex;
    display:flex;
}

.div-vertical-content{
	-webkit-align-self:flex-end;
	        align-self:flex-end;
}

.btn-group{
	margin-top:3px;
}

.table-row > .container-fluid {
	padding:0px;
}

.table-row-top > .container-fluid {
	padding-left:10px;
}

.col-md-11, .col-md-1{
	padding-right:2px;
}

.footer{
	margin:10px 0 0 0;
	position:absolute;
	width:100%;
	bottom:0;
	color:#777;
    background:#181B1E;
	padding-bottom:20px;
}

.footer li{
    margin:9px 0;
}

.footer-heading{
	color:#bbb;
	margin-top:36px;
	margin-bottom:9px;
}

.footer-link a{
	color:#999;
}

.jumbotron{
	background:#1a1f24;
	color:#eee;
    padding-bottom:1rem;
}

.btn-selected{
	color: #fff;
	background-color: #545B66;
	border-color: #4e555b;
}

pre {
	display: block;
	font-family: monospace;
	white-space: pre;
	word-wrap:normal;
	margin: 1em 0;
	color:#ff9700;
    font-size:14px;
}

@media only screen and (max-width: 600px) {
	.table-row-image-container{
		width:100%;
	}
	.table-row-add-button-container{
		width:100%;
	}
	.table-row-add-button{
		width:100%;
		height:100px;
	}
	.table-row{
		margin:0px 10px 20px 0px;
	}
}

@media only screen and (max-width: 767px) {
    #root{
	  padding-bottom:620px;
    }
}

.demo-url-static{
  color:#444;
}

.demo-url-dynamic{
  color:#222;
  font-weight:bold;
}

.blinking-cursor {
  font-size: 16px;
  color: gray;
  -webkit-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: gray;
  }
}

@-webkit-keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: gray;
  }
}

.demo-url{
    font-size:16px;
    width:340px;
    height:56px;
    padding:10px;
    background:#eee;
}

.demo-info{
    font-size:16px;
    width:340px;
    background:#eee;
    color:#444;
}

.demo-info-desc{
    font-style:italic;
    font-size:16px;
    color:black;
    text-align:center;
    padding:10px;
}

.demo-info-width{
    font-size:14px;
    color:#444;
    padding:0 10px 2px 10px;
}

.demo-info-height{
    font-size:14px;
    color:#444;
    padding:0 10px 2px 10px;
    text-align:right;
    
}

.demo-info-scale{
    font-size:14px;
    padding:0 10px 2px 10px;
    text-align:center;
}

.demo-container{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:middle;
            align-items:middle;
    width:340px;
    height:340px;
    padding:10px;
}

.demo-image-container{
    display:-webkit-flex;
    display:flex;
    margin:auto;
    transition: all .3s ease-in-out;
    background:black;
}

.demo-image-container-img{
    margin:auto;
    background-repeat:no-repeat;
}

.demo-image-container-img-0{
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&crop");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=3&crop") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=3&crop") 3x
    );
    background-size:cover;
}

.demo-image-container-img-1{ 
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&crop");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=3&crop") 3x
    );
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=3&crop") 3x
    );
    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=3&crop") 3x
    );
    background-size:cover;
}

.demo-image-container-img-2{
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&crop");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=3&crop") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=3&crop") 3x
    );
    background-size:cover;
}

.demo-image-container-img-3{
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&fit");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=3&fit") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=3&fit") 3x
    );
    background-size:contain;
}

.demo-image-container-img-4{ 
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&fit");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=3&fit") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=3&fit") 3x
    );
    background-size:contain;
}

.demo-image-container-img-5{ 
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&fit");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=3&fit") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=3&fit") 3x
    );
    background-size:contain;
}

.demo-image-container-img-6{ 
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&fit");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=3&fit") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=3&fit") 3x
    );
    background-size:contain;
}

.demo-image-container-img-7{ 
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&crop");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=3&crop") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=3&crop") 3x
    );
    background-size:cover;
}

.home-banner {
	margin-top:40px;
    text-align:center;
}

.home-banner p{
    color:#ccc;
    line-height:1.4;
}

.home-banner ul{
    list-style:none;
	-webkit-flex-grow:1;
	        flex-grow:1;
	margin:20px 0;
}

.home-banner li{
    color:black;
    font-size:17px;
    margin:6px 0px;
}

.home-banner .btn{
	width:200px;
    font-weight:bold;
	letter-spacing:0.5px;
}

.home-banner h1{
    color:#fff;
	font-family: 'Roboto Slab', serif;
	font-weight:100;
}

.home-banner h2{
	color:#ccc;
}

.home-banner .card{
    background-color:#1a1f24;
	font-weight:300;
	font-size:16px;
}

.home-banner .card-title{
	color:#ffc107;
	font-family: 'Roboto Slab', serif;
}

.extra-bold{
	font-weight:900;
}

.home-banner .jumbotron{
	background-color:#ffc107;
	background-size:cover;
    text-align:left;
}

.col-flex-min-320{
    -webkit-flex:1 1;
            flex:1 1;
    min-width:320px;
    padding:0 10px 0 10px;
	display:-webkit-flex;
	display:flex;
	-webkit-flex-direction:column;
	        flex-direction:column;
}

.col-min-320{
    margin-top:-48px;
    min-width:320px;
    background:white;
	background-clip: border-box;
	border: 1px solid rgba(0,0,0,.125);
	border-radius: .25rem;
	height:100%;
	margin-left:auto;
	margin-right:auto;
}

@media only screen and (max-width: 992px) {
	.col-min-320{
        margin-top:20px;
    }
	.home-banner .btn{
		margin:auto;
	}
}


.doc-content{
	margin-top:20px;
	font-size:16px;
}

.doc-content ul, .doc-content li {
	font-size:16px;
    line-height:1.5;	
    list-style:outside;
	margin-left:8px;
}

.doc-content ol li {
    list-style-type:decimal;
}

.doc-content p{
	font-size:16px;
}

.doc-content .btn{
	min-width:200px;
}

.doc-hl {
    color:#ff9700;
    font-weight:bold;
}

.doc-content a, .doc-content a:hover {
    color:#0366d6;
}

.doc-content a.active {
    color:#ffce3d;
}

.doc-hl-lp {
    padding-left:15px;
    color:#ffce3d;
}

.doc-sec {
    color:#666;
}

.doc-content .card-body {
    line-height:1.3;
    margin-right:20px;
}

.doc-content-indended {
    margin-left:20px;
}

#root, body {
    overflow:visible;
}
.preview-content{
	margin-top:20px;
    color:#777;
    text-align:center;
    font-size:14px;
}

.preview-content .url-view-final{
    background:#333;
    padding:0;
    margin-bottom:1px;
    display:-webkit-flex;
    display:flex;
}

.preview-content .url-container-final{
    margin:auto;
    display:inline-block;
}

.preview-content .url-value-final{
    color: #bbb;
    font-size:18px;
}

.preview-content .url-params-final{
    color: #eee;
    font-size:18px;
}

.preview-button-holder{
	width:80px;
}

.preview-params-row {
	background:#222;
	padding: 10px;
    margin-bottom:1px;
    color:#aaa;
}

.preview-divider {
    height:2px;
    background-color:#222;
    margin:5px;
}

.preview-crop-fit-row {
	padding:4px;
	background:#222;
    margin-bottom:10px;
}

.preview-content label{
	margin:auto;
}

.preview-crop-fit {
	margin:auto;
	width:200px;
}

.preview-container{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:middle;
            align-items:middle;
    padding:10px;
    margin:auto;
}

.preview-image-container{
    display:-webkit-flex;
    display:flex;
    margin:auto;
    background:#1a1f24;
    background-image: linear-gradient(45deg, #404040 25%, transparent 25%), linear-gradient(-45deg, #404040 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #404040 75%), linear-gradient(-45deg, transparent 75%, #404040 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.preview-image{
    margin:auto;
    object-fit:contain;
}

.preview-image-hidden{
    margin:auto;
    object-fit:contain;
    display:none;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background:none;
  border: 0;
  height:16px;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #444;
  border-radius: 3px;
  border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #444;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #444;
  border-radius: 3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #444;
  border: 0.2px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #444;
  border: 0.2px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #444;
}
input[type=range]:focus::-ms-fill-upper {
  background: #444;
}

.static-preview-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.static-preview-row{
  -webkit-justify-content:space-evenly;
          justify-content:space-evenly;
}

.static-preview-item-div{
    margin:5px;
}

.static-preview-image-container {
    display:-webkit-flex;
    display:flex;
    background:#1a1f24;
    background-image: linear-gradient(45deg, #404040 25%, transparent 25%), linear-gradient(-45deg, #404040 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #404040 75%), linear-gradient(-45deg, transparent 75%, #404040 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.static-preview-image {
   margin:auto;
   object-fit:contain;
}

.static-preview-crop-div, .static-preview-fit-div {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
           flex-direction: column;
   -webkit-flex-wrap: wrap;
           flex-wrap: wrap;
}

.preview-info-width{
    font-size:14px;
    color:#aaa;
}

.preview-info-height{
    font-size:14px;
    color:#aaa;
    text-align:right;
}

.preview-info-ratio{
    font-size:14px;
    color:#aaa;
    text-align:center;
    -webkit-flex-grow:0;
            flex-grow:0;
}



.preview-tooltip .tooltip-inner{
 	background-color:#ccc;
    color:#444;
}

.preview-tooltip .arrow::before {
  border-top-color: #ccc;
}


.pricing-column ul {
    margin:10px 0 10px 0;
	background:#eee;
	border: 1px solid #aaa;
}

.pricing-column li {
   list-style-type:none; 
   text-align:center;
   padding:20px 5px 20px 5px;
   margin:0;
   border-bottom: 1px solid #ccc;
}

.pricing-column .header {
  background-color: #ffc107;
  color: black;
  font-size: 22px;
  font-weight: bold;
  padding:2px 0px 20px 0px;
  border:none;
}

.pricing-column .header-small {
  background-color: #ffc107;
  color: black;
  font-size: 16x;
  padding:20px 0px 2px 0px;
  border:none;
}

.pricing-column .price {
  font-size:36px;
  font-weight:bold;
  padding:20px 20px 0px 20px;
  border:none;
}

.pricing-column .details-big{
  padding:20px 10px 0px 10px;
  border:none;
}

.pricing-column .currency{
  font-size:20px;
  font-weight:bold;
  vertical-align:text-top;
}

.pricing-column .price-small {
  color:#777;
  margin-top:-5px;
  padding:0px 20px 20px 20px;
}

.pricing-column .details-small {
  color:#777;
  padding:0px 20px 20px 20px;
}

.pricing-column a, .pricing-column a:hover {
  color:#212529;
  font-weight:bold;
}

.pricing-column .number{
  font-size:18px;
  font-weight:bold;
}

.pricing-column .additional-info{
  min-height:89px;
}



.pricing-content .jumbotron {
  margin:10px 0 10px 0;
  font-size:18px;
  text-align:center;
}

.pricing-content .jumbotron p{
  color:#ddd;
  line-height:1.4;
}

.pricing-content .jumbotron a{
  font-weight:bold;
}

.pricing-content .action-btn{
  padding:8px;
}

.terms {
	color:#eee;
}

.terms li{
	margin:2px;
	list-style:inside;
}

.terms ul{
	margin-bottom:16px;
}


.contact {
	color:#eee;
	text-align:center;
}

.contact p {
	margin-top:20px;
}

