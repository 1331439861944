@media all and (min-width: 480px) {
  .login {
    padding: 20px 0 20px 0;
  }

  .login form, .login-bottom {
    margin: 0 auto;
    max-width: 380px;
  }
}


.login {
    padding: 5px 0 10px 0;
}

.login form{
    color:#ddd;
	padding:15px;
    background: #1a1f24;
    border:1px solid #222;
}

.login input, .login input:focus{
    color:white;
	background: black;
	border-color:#333;
}

.login select, .login select:focus{
    color:white;
	background: black;
	border-color:#333;
    line-height:2;
}

.login input:focus, .login select:focus{
	box-shadow: 0 0 0 3px #9e7700;
}

.login-bottom{
    color:#eee;
	padding:15px;
	margin-bottom:20px;
    background: #1a1f24;
	text-align:center;
    border:1px solid #333;
}

a:hover {
    color:#ffce3d;
}

a {
    color:#c99700;
}
