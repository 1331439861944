.home-banner {
	margin-top:40px;
    text-align:center;
}

.home-banner p{
    color:#ccc;
    line-height:1.4;
}

.home-banner ul{
    list-style:none;
	flex-grow:1;
	margin:20px 0;
}

.home-banner li{
    color:black;
    font-size:17px;
    margin:6px 0px;
}

.home-banner .btn{
	width:200px;
    font-weight:bold;
	letter-spacing:0.5px;
}

.home-banner h1{
    color:#fff;
	font-family: 'Roboto Slab', serif;
	font-weight:100;
}

.home-banner h2{
	color:#ccc;
}

.home-banner .card{
    background-color:#1a1f24;
	font-weight:300;
	font-size:16px;
}

.home-banner .card-title{
	color:#ffc107;
	font-family: 'Roboto Slab', serif;
}

.extra-bold{
	font-weight:900;
}

.home-banner .jumbotron{
	background-color:#ffc107;
	background-size:cover;
    text-align:left;
}

.col-flex-min-320{
    flex:1;
    min-width:320px;
    padding:0 10px 0 10px;
	display:flex;
	flex-direction:column;
}

.col-min-320{
    margin-top:-48px;
    min-width:320px;
    background:white;
	background-clip: border-box;
	border: 1px solid rgba(0,0,0,.125);
	border-radius: .25rem;
	height:100%;
	margin-left:auto;
	margin-right:auto;
}

@media only screen and (max-width: 992px) {
	.col-min-320{
        margin-top:20px;
    }
	.home-banner .btn{
		margin:auto;
	}
}

