html,body{
	height:100%;
	position:relative;
	background:black;
	-webkit-font-smoothing:antialiased;
	font-family:Roboto,Helvetica,Arial,sans-serif;
}

#root{
	min-height:100vh;
	position:relative;
	overflow:hidden;
	display:block;
	padding-top:50px;
	padding-bottom:260px;
}

.header{
	background:#343a40;
}

.header-center{
    margin-top:10px;
    text-align:center;
}

.header-center-title{
    color:#ddd;
    text-align:center;
    margin-top:20px;
    font-size:28px;
}

.header-center-subtitle{
    color:#ddd;
    text-align:center;
    margin:10px 0 5px 0;
    font-size:28px;
}

.page {
	margin:20px;
}

.interactions {
	margin-top:20px;
	text-align:center;
}

.table {
	margin: 15px 0;
	color:#888;
}

.url-value{
    font-size:18px;
	color:white;
	white-space:normal;
	word-wrap:anywhere;
	word-wrap:break-word;
}
.text-value {
	color:#ddd;
	white-space:normal;
	word-wrap:anywhere;
	word-wrap:break-word;
}

.table-header{
	display: flex;
	line-height: 24px;
	font-size: 16px;
	padding:0 10px;
	justify-content: space-between;
}

.table-empty {
	color:#eee;
	margin: 200px;
	text-align: center;
	font-size: 16px;
}

.table-row-top {
	margin-top:5px;
	display: flex;
	white-space: nowrap;
}

.table-row-bottom{ 
	display: flex;
	flex-wrap: wrap;
	align-items:flex-start;
    padding:5px;
}

.table-row-image-container{
	margin:5px;	
	min-height:210px;
	min-width:260px;
	border:1px solid #444;
	transition: background .15s ease-in-out, border-color .15s ease-in-out;
	position:relative;
}

.table-overlay{
	position: absolute;
	top: 0;
	width: 100%;
    height: 100%;
	background: #222;
	opacity: 0.6;
	z-index: 1;
}

.table-overlay> .spinner-border{
	position:absolute;
	left:50%;
	margin-left:-16px;
	top:50%;
	margin-top:-16px;
    color:white;
}

.table-row-add-button-container{
	margin:5px;
}

.table-row-action-buttons-container{
    display:flex;
	flex-grow:1;
	justify-content:flex-end;
	align-self:flex-end;
}

.table-action-button {
    margin:5px;
	width:100px;
}

.table-action-button-large {
    margin:5px;
	width:150px;
}

.table-commiting-container{
    margin:10px;
}

.table-row-image-content{
	margin:5px;	
    text-align:center;
	flex-grow:1;
	position:relative;
    background-image: linear-gradient(45deg, #404040 25%, transparent 25%), linear-gradient(-45deg, #404040 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #404040 75%), linear-gradient(-45deg, transparent 75%, #404040 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.table-row-image-content:before{
    content:' ';
    display:inline-block;
    vertical-align:middle;
    height:100%;
}

.table-row-image-row{
	display:flex;
	text-align:center;
}


.table-row-right-container{
	float:right;
    margin:5px 5px 5px 0px;
}

.table-row-bottom-container{
    margin:0px 5px 10px 5px;
	text-align:center;
}

.table-row-lang-container{
    margin: 5px;
    padding: 5px 1px 0 1px;
    border-top: 1px solid #444;
}

.table-row-lang-label{
	font-size:16px
}

.table-row-lang-value{
	color:#eee;
	font-size:16px;
	cursor:pointer;
}

.table-row-lang-container .btn{
	padding: 0 6px;
}

.table-row-bottom-container > .btn-group > .btn,
.div-vertical-content> .btn-group > .btn{
    padding: 0 6px;
    width:67px;
}

.table-row-image-container:hover{
	background:black;
	border-color:#666;
}

.table-row-image{
	max-height:200px;
	max-width:100%;
	object-fit: contain;
	cursor:pointer;
	line-height:200px;
	text-align:center;
    display:inline-block;
    vertical-align:middle;
}

.table-row-add-button{
	color:#ffc107;
	display:flex;
	flex-direction:column;
	width:260px;
	height:260px;
	text-align:center;
	cursor:pointer;
	border:1px solid #444;
	box-sizing:border-box;
	transition: color .15s ease-in-out, background .15s ease-in-out, border-color .15s ease-in-out;
	margin:0;
}

.table-row-add-button:hover{
	color:#ecb306;
	background:black;
	border-color:#666;
}

.table-row-add-button-content{
	margin:auto;
}

.table-row-title{
	padding:2px 1px;
	background:#4f575e;
	text-align:center;
	border-radius:5px 5px 0px 0px;
	border:1px solid #4f575e;
    color:#ccc
}

.table-row{
	position:relative; 
	white-space: nowrap;
	margin:10px 10px 20px 10px;
	background: #1a1f24;
	border-radius:5px;
	border:1px solid #1a1f24;
}

.table-row > .spinner-border{
	position:absolute;
	left:50%;
	margin-left:-16px;
	top:50%;
	margin-top:-16px;
}

.table-header > span {
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5px;
}

.table-row > span {
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5px;
}

.button-inline {
	border-width: 0;
	background: transparent;
	color: inherit;
	text-align: inherit;
	-webkit-font-smoothing: inherit;
	padding: 0;
	font-size: inherit;
	cursor: pointer;
}

.button-active {
	border-radius: 0;
	border-bottom: 1px solid #38BB6C;
}

.button-holder {
	display:inline-block;
	width:260px;
}

.button-delete {
    float:right;
	cursor: pointer;
    padding: 15px 7px 5px 17px;
}

.button-delete-imageset {
	float:right;
	cursor: pointer;
}

.button-delete > .material-icons, .button-delete-imageset > .material-icons{
	color:#c62828;
	vertical-align:middle;
	transition: color .15s ease-in-out, background .15s ease-in-out, border-color .15s ease-in-out;
}

.button-delete > .material-icons:hover, .button-delete-imageset > .material-icons:hover{
	color:#f44336;
}

.button-edit-imageset {
	float:right;
	cursor: pointer;
}

.button-copy-url{
	cursor: pointer;
	margin: 0 5px;
    padding: 0 5px;
}

.button-edit > .material-icons, .button-edit-imageset > .material-icons, .button-copy-url > .material-icons{
	color:#999;
	vertical-align:middle;
	transition: color .15s ease-in-out, background .15s ease-in-out, border-color .15s ease-in-out;
}

.button-edit> .material-icons:hover, .button-edit-imageset > .material-icons:hover, .button-copy-url > .material-icons:hover{
	color:#bbb;
}

.btn-secondary{
	color:#ccc;
}

.div-vertical{
    transform: rotate(-90deg) translate(0,45px);
    transform-origin: left bottom;
	width:45px;
	height:200px;
    text-align:center;
    display:flex;
}

.div-vertical-content{
	align-self:flex-end;
}

.btn-group{
	margin-top:3px;
}

.table-row > .container-fluid {
	padding:0px;
}

.table-row-top > .container-fluid {
	padding-left:10px;
}

.col-md-11, .col-md-1{
	padding-right:2px;
}

.footer{
	margin:10px 0 0 0;
	position:absolute;
	width:100%;
	bottom:0;
	color:#777;
    background:#181B1E;
	padding-bottom:20px;
}

.footer li{
    margin:9px 0;
}

.footer-heading{
	color:#bbb;
	margin-top:36px;
	margin-bottom:9px;
}

.footer-link a{
	color:#999;
}

.jumbotron{
	background:#1a1f24;
	color:#eee;
    padding-bottom:1rem;
}

.btn-selected{
	color: #fff;
	background-color: #545B66;
	border-color: #4e555b;
}

pre {
	display: block;
	font-family: monospace;
	white-space: pre;
	word-wrap:normal;
	margin: 1em 0;
	color:#ff9700;
    font-size:14px;
}

@media only screen and (max-width: 600px) {
	.table-row-image-container{
		width:100%;
	}
	.table-row-add-button-container{
		width:100%;
	}
	.table-row-add-button{
		width:100%;
		height:100px;
	}
	.table-row{
		margin:0px 10px 20px 0px;
	}
}

@media only screen and (max-width: 767px) {
    #root{
	  padding-bottom:620px;
    }
}
