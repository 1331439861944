#root, body {
    overflow:visible;
}
.preview-content{
	margin-top:20px;
    color:#777;
    text-align:center;
    font-size:14px;
}

.preview-content .url-view-final{
    background:#333;
    padding:0;
    margin-bottom:1px;
    display:flex;
}

.preview-content .url-container-final{
    margin:auto;
    display:inline-block;
}

.preview-content .url-value-final{
    color: #bbb;
    font-size:18px;
}

.preview-content .url-params-final{
    color: #eee;
    font-size:18px;
}

.preview-button-holder{
	width:80px;
}

.preview-params-row {
	background:#222;
	padding: 10px;
    margin-bottom:1px;
    color:#aaa;
}

.preview-divider {
    height:2px;
    background-color:#222;
    margin:5px;
}

.preview-crop-fit-row {
	padding:4px;
	background:#222;
    margin-bottom:10px;
}

.preview-content label{
	margin:auto;
}

.preview-crop-fit {
	margin:auto;
	width:200px;
}

.preview-container{
    display:flex;
    align-items:middle;
    padding:10px;
    margin:auto;
}

.preview-image-container{
    display:flex;
    margin:auto;
    background:#1a1f24;
    background-image: linear-gradient(45deg, #404040 25%, transparent 25%), linear-gradient(-45deg, #404040 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #404040 75%), linear-gradient(-45deg, transparent 75%, #404040 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.preview-image{
    margin:auto;
    object-fit:contain;
}

.preview-image-hidden{
    margin:auto;
    object-fit:contain;
    display:none;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background:none;
  border: 0;
  height:16px;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #444;
  border-radius: 3px;
  border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #444;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #444;
  border-radius: 3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #444;
  border: 0.2px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #444;
  border: 0.2px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #444;
}
input[type=range]:focus::-ms-fill-upper {
  background: #444;
}

.static-preview-container{
  display: flex;
  flex-direction: column;
}

.static-preview-row{
  justify-content:space-evenly;
}

.static-preview-item-div{
    margin:5px;
}

.static-preview-image-container {
    display:flex;
    background:#1a1f24;
    background-image: linear-gradient(45deg, #404040 25%, transparent 25%), linear-gradient(-45deg, #404040 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #404040 75%), linear-gradient(-45deg, transparent 75%, #404040 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.static-preview-image {
   margin:auto;
   object-fit:contain;
}

.static-preview-crop-div, .static-preview-fit-div {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
}

.preview-info-width{
    font-size:14px;
    color:#aaa;
}

.preview-info-height{
    font-size:14px;
    color:#aaa;
    text-align:right;
}

.preview-info-ratio{
    font-size:14px;
    color:#aaa;
    text-align:center;
    flex-grow:0;
}



.preview-tooltip .tooltip-inner{
 	background-color:#ccc;
    color:#444;
}

.preview-tooltip .arrow::before {
  border-top-color: #ccc;
}

