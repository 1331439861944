.terms {
	color:#eee;
}

.terms li{
	margin:2px;
	list-style:inside;
}

.terms ul{
	margin-bottom:16px;
}

