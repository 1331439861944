.demo-url{
    font-size:16px;
    width:340px;
    height:56px;
    padding:10px;
    background:#eee;
}

.demo-info{
    font-size:16px;
    width:340px;
    background:#eee;
    color:#444;
}

.demo-info-desc{
    font-style:italic;
    font-size:16px;
    color:black;
    text-align:center;
    padding:10px;
}

.demo-info-width{
    font-size:14px;
    color:#444;
    padding:0 10px 2px 10px;
}

.demo-info-height{
    font-size:14px;
    color:#444;
    padding:0 10px 2px 10px;
    text-align:right;
    
}

.demo-info-scale{
    font-size:14px;
    padding:0 10px 2px 10px;
    text-align:center;
}

.demo-container{
    display:flex;
    align-items:middle;
    width:340px;
    height:340px;
    padding:10px;
}

.demo-image-container{
    display:flex;
    margin:auto;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background:black;
}

.demo-image-container-img{
    margin:auto;
    background-repeat:no-repeat;
}

.demo-image-container-img-0{
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&crop");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=3&crop") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=3&crop") 3x
    );
    background-size:cover;
}

.demo-image-container-img-1{ 
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&crop");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=3&crop") 3x
    );
    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=3&crop") 3x
    );
    background-size:cover;
}

.demo-image-container-img-2{
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&crop");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=3&crop") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=80&h=80&x=3&crop") 3x
    );
    background-size:cover;
}

.demo-image-container-img-3{
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&fit");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=3&fit") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=160&x=3&fit") 3x
    );
    background-size:contain;
}

.demo-image-container-img-4{ 
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&fit");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=3&fit") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=320&h=320&x=3&fit") 3x
    );
    background-size:contain;
}

.demo-image-container-img-5{ 
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&fit");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=3&fit") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=270&h=320&x=3&fit") 3x
    );
    background-size:contain;
}

.demo-image-container-img-6{ 
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&fit");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=3&fit") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=1&fit") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=1.5&fit") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=2&fit") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=214&h=320&x=3&fit") 3x
    );
    background-size:contain;
}

.demo-image-container-img-7{ 
    background-image:url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&crop");
    background-image: -webkit-image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=3&crop") 3x
    );

    background-image: image-set(
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=1&crop") 1x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=1.5&crop") 1.5x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=2&crop") 2x,
      url("https://cdn.imageopt.com/2e0d6k-p25/q?w=160&h=320&x=3&crop") 3x
    );
    background-size:cover;
}
